<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
.container-box {
  padding-bottom: 15px;
  .add-btn2 {
    background-color: #b767f8;
    border-color: #b767f8;
    &:hover,
    &:focus {
      background-color: #c086f0;
      border-color: #c086f0;
    }
  }
  .tool-bar {
    border-bottom: 1px solid #ededed;
  }
  .tree-box {
    width: 100%;
    height: calc(100% - 82px);
    overflow: scroll;
    margin-top: 30px;
  }
  ::v-deep {
    .is-leaf {
      .tree-label {
        .tree-item {
          display: block;
        }
        .label-name {
          margin-bottom: 4px;
        }
        .el-button {
          padding: 6px 0;
        }
      }
    }
    .org-tree-node:after,
    .org-tree-node-children:before,
    .org-tree-node:not(:first-child):before,
    .org-tree-node:not(:last-child):after {
      border-width: 2px;
      border-color: #999;
    }
    .tree-label {
      background-color: $color-primary-1;
      color: #999;
      padding-bottom: 6px;
      .tree-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .label-name{
        font-size: 18px;
        color: #999;
      }
      .el-button {
        margin: 0 4px;
        padding: 6px 0 6px 6px;
        &--text {
          font-size: 18px;
          color: #999;
        }
        &.tree-edit {
          &:hover,
          &:focus {
            color: $color-primary-4;
            .el-icon-edit {
              font-weight: bold;
            }
          }
        }
        &.tree-delete {
          &:hover,
          &:focus {
            color: $color-danger-3;
            .el-icon-delete {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
.tel-box {
  display: flex;
  align-items: center;
  .el-form-item {
    flex-grow: 1;
    margin-right: 10px;
  }
}
.tel-list {
  margin-top: 10px;
  .tel-item {
    height: 36px;
    font-size: 16px;
    align-items: center;
    display: flex;
    .el-button {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.img-code {
  width: 100%;
  margin-left: -48px;
  margin-top: 20px;
}
</style>

<template>
  <!-- 角色管理 -->
  <section class="page-container">
    <div class="container-box" id="containerBox">
      <!-- 内页工具栏 -->
      <div class="tool-bar">
        <div class="tool-left">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            v-if="smallRoleObj.add_company"
            @click="handleAdd('company')"
          >
            添加单位
          </el-button>
          <el-button
            class="add-btn2"
            size="small"
            type="primary"
            icon="el-icon-plus"
            v-if="smallRoleObj.add_project"
            @click="handleAdd('project')"
          >
            添加项目
          </el-button>
          <el-button
            size="small"
            type="warning"
            icon="el-icon-download"
            v-if="smallRoleObj.down"
            @click="dialogCodeVisible = true"
          >
            下载二维码
          </el-button>
        </div>
        <div class="tool-right">
          <el-tooltip effect="dark" content="刷新">
            <el-button
              size="small"
              icon="el-icon-refresh-right"
              @click="handleRefresh"
            />
          </el-tooltip>
          <el-tooltip effect="dark" content="全屏">
            <el-button size="small" @click="handleFullScreen">
              <span class="iconfont">
                {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
              </span>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- 组织架构树形表 -->
      <div class="tree-box">
        <vue2-org-tree
          :data="treeData"
          label-class-name="tree-label"
          :renderContent="renderContent"
        />
      </div>
    </div>
    <el-dialog
      :title="(currentData.id && '单位编辑') || '添加单位'"
      :visible.sync="dialogCompanyVisible"
      width="560px"
      @close="cancleModify('company')"
    >
      <el-form
        :model="companyFormData"
        ref="companyFormData"
        label-width="84px"
        :rules="rules"
      >
        <el-form-item
          label="父级单位:"
          prop="pid"
          v-if="!currentData || (currentData && currentData.pid != 0)"
        >
          <el-select
            v-model="companyFormData.pid"
            placeholder="选择单位"
            clearable
          >
            <el-option
              v-for="item in companyListSearch"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <p>
                <template v-if="item.hierarchy > 0">
                  <span
                    class="hierarchy iconfont"
                    v-for="num in item.hierarchy"
                    :key="num"
                  >
                    &#xe603;
                  </span>
                </template>
                {{ item.name }}
              </p>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称:" prop="name">
          <el-input
            v-model="companyFormData.name"
            placeholder="请输入单位名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="单位地址:">
          <el-input
            v-model="companyFormData.address"
            placeholder="请输入单位地址"
            clearable
          />
        </el-form-item>
        <el-form-item label="联系电话:">
          <div class="tel-box">
            <el-form-item prop="telItem.name">
              <el-input
                v-model="companyFormData.telItem.name"
                placeholder="名称"
                clearable
              />
            </el-form-item>
            <el-form-item prop="telItem.tel">
              <el-input
                v-model="companyFormData.telItem.tel"
                placeholder="电话号码"
                clearable
              />
            </el-form-item>
            <el-button
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addCompanyTel"
            >
              添加
            </el-button>
          </div>
          <ul class="tel-list">
            <li
              class="tel-item"
              v-for="(item, index) in companyFormData.tel"
              :key="index"
            >
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="deleteCompanyTel(index)"
              />
              <p>
                <span>{{ item.name }}：</span>
                <span>{{ item.tel }}</span>
              </p>
            </li>
          </ul>
        </el-form-item>
        <el-form-item label="状态:">
          <el-radio-group v-model="companyFormData.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleModify('company')">取 消</el-button>
        <el-button type="primary" @click="handleSubmitCompany">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="(currentData.id && '项目编辑') || '添加项目'"
      :visible.sync="dialogProjectVisible"
      width="560px"
      @close="cancleModify('project')"
    >
      <el-form
        :model="projectFormData"
        ref="projectFormData"
        label-width="96px"
        :rules="rules2"
      >
        <el-form-item label="项目名称:" prop="name">
          <el-input
            v-model="projectFormData.name"
            placeholder="请输入项目名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="名称首字母:" prop="first_letter">
          <!-- <el-input
            v-model="projectFormData.first_letter"
            placeholder="请输入名称首字母"
            clearable
          /> -->
          <el-select
            v-model="projectFormData.first_letter"
            placeholder="请选择名称首字母"
            clearable
          >
            <el-option
              v-for="item in letterList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属单位:" prop="company_id">
          <el-select
            v-model="projectFormData.company_id"
            placeholder="选择单位"
            clearable
          >
            <el-option
              v-for="item in companyListSearch"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <p>
                <template v-if="item.hierarchy > 0">
                  <span
                    class="hierarchy iconfont"
                    v-for="num in item.hierarchy"
                    :key="num"
                  >
                    &#xe603;
                  </span>
                </template>
                {{ item.name }}
              </p>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目地址:" prop="address">
          <el-input
            v-model="projectFormData.address"
            placeholder="请输入项目地址"
            clearable
          />
        </el-form-item>
        <el-form-item label="工单类型:" prop="work_order_ids">
          <el-checkbox-group
            v-model="projectFormData.work_order"
            size="mini"
            @change="workOrderChange"
          >
            <el-checkbox
              v-for="item in orderType"
              :key="item.id"
              :label="item.id"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.desc"
                placement="right"
                :disabled="!item.desc"
              >
                <p>{{ item.name }}</p>
              </el-tooltip>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态:">
          <el-radio-group v-model="projectFormData.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleModify('project')">取 消</el-button>
        <el-button type="primary" @click="handleSubmitProject">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="项目二维码下载查询"
      :visible.sync="dialogCodeVisible"
      width="380px"
      @close="cancleModify('code')"
    >
      <el-form label-width="96px">
        <el-form-item label="项目二维码:">
          <el-select
            v-model="projectId"
            placeholder="选择项目"
            clearable
            @change="createCode"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="" v-if="codePath">
          <img class="img-code" :src="codePath" alt="" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleModify('code')">取 消</el-button>
        <el-button
          type="primary"
          @click="downLoadCode"
          :loading="downLoadLoading"
        >
          下 载
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  $osData,
  $osCompanyAdd,
  $osCompanyDelete,
  $osCompanyDetail,
  $osCompanyUpdate,
  $osCreateWxQrcode,
  $osWxQrcodeDown,
} from "@api/company";
import { getWorkOrderList } from "@api/workOrder";
import {
  getProjectList,
  $createProject,
  $projectDetail,
  $projectUpdate,
  $projectDelete,
} from "@api/project";
import { mapState, mapActions } from "vuex";
import { validTel } from "@utils/validate";
export default {
  name: "os",
  inject: ["reload"],
  computed: {
    ...mapState("system", ["companyListSearch"]),
  },
  data() {
    return {
      isFull: false, //全屏开启
      treeData: {},
      companyFormData: {
        status: 1,
        tel: [],
        telItem: {},
      },
      letterList: [],
      smallRoleObj: {},
      projectFormData: {
        status: 1,
        work_order: [],
      },
      currentData: {}, // 当前操作节点数据
      dialogCompanyVisible: false,
      dialogProjectVisible: false,
      dialogCodeVisible: false,
      orderType: [], // 工单类型
      projectList: [], // 项目列表
      projectId: null,
      codePath: "",
      downLoadLoading: false,
      rules: {
        pid: [{ required: true, message: "请选择父级单位", trigger: "change" }],
        name: [{ required: true, message: "请填写单位名称", trigger: "blur" }],
        address: [
          { required: true, message: "请填写单位地址", trigger: "blur" },
        ],
        "telItem.name": [
          {
            validator: (rule, value, callback) => {
              if (this.companyFormData.telItem.tel && !value) {
                callback(new Error("请填写联系电话名称"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        "telItem.tel": [
          {
            validator: (rule, value, callback) => {
              if (this.companyFormData.telItem.name && !value) {
                callback(new Error("请填写联系电话号码"));
              } else if (value && !validTel(value)) {
                callback(new Error("错误的号码格式"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      rules2: {
        company_id: [
          { required: true, message: "请选择所属单位", trigger: "change" },
        ],
        name: [{ required: true, message: "请填写项目名称", trigger: "blur" }],
        first_letter: [
          {
            required: true,
            validator: (rule, value, callback) => {
              const reg = /^[A-Z]+$/;
              if (!value) {
                callback(new Error("请选择名称首字母"));
              } else if (!reg.test(value)) {
                callback(new Error("请填写大写英文字母"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请填写项目地址", trigger: "blur" },
        ],
        work_order_ids: [
          { required: true, message: "请选择工单类型", trigger: "change" },
        ],
      },
    };
  },
  async created() {
    console.log(this.companyListSearch);
    this.getOsData();
    this.getOrderType();
    this.getProjectList();
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  async mounted() {
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
    for (let i = 0; i < 26; i++) {
      this.letterList.push(String.fromCharCode(65 + i).toUpperCase());
    }
  },
  methods: {
    ...mapActions("system", ["getCompanyList"]),
    /** 获取组织架构树型表 */
    async getOsData() {
      let res = await $osData();
      if (res.code != 200) return;
      this.treeData = res.data[0];
    },
    /** 获取工单类型列表 */
    async getOrderType() {
      let res = await getWorkOrderList();
      if (res.code != 200) return;
      this.orderType = res.data;
    },
    /** 获取项目列表 */
    async getProjectList() {
      let res = await getProjectList();
      if (res.code != 200) return;
      this.projectList = res.data;
    },
    /** 获取单位详情 */
    async getCompanyDetail() {
      let res = await $osCompanyDetail(
        this.currentData.real_id || this.currentData.id
      );
      if (res.code != 200) return;
      this.companyFormData = {
        ...this.companyFormData,
        id: res.data.id,
        pid: res.data.pid,
        address: res.data.address,
        name: res.data.name,
        tel: res.data.tel,
        status: res.data.status,
      };
    },
    /** 获取项目详情 */
    async getProjectDetail() {
      let res = await $projectDetail(this.currentData.real_id);
      if (res.code != 200) return;
      this.projectFormData = {
        ...this.projectFormData,
        id: res.data.id,
        name: res.data.name,
        company_id: res.data.company_id,
        address: res.data.address,
        work_order_ids: res.data.work_order_ids,
        work_order: res.data.work_order_ids_arr.map((item) => parseInt(item)),
        status: res.data.status,
        first_letter: res.data.first_letter,
      };
    },
    /** 节点修改 */
    async nodeModify(data) {
      this.currentData = data;
      if (data.data_type == "project") {
        await this.getProjectDetail();
        this.dialogProjectVisible = true;
      } else {
        await this.getCompanyDetail();
        this.dialogCompanyVisible = true;
      }
    },
    /** 生成项目二维码 */
    async createCode() {
      let res = await $osCreateWxQrcode(this.projectId);
      if (res.code != 200) return;
      this.codePath = res.data.full_path;
    },
    async downLoadCode() {
      this.downLoadLoading = true;
      await $osWxQrcodeDown(this.projectId);
      this.downLoadLoading = false;
      this.cancleModify("code");
    },
    /** 节点删除 */
    nodeDelete(data) {
      this.currentData = data;
      let msg = (data.data_type == "project" && "项目") || "单位";
      this.$confirm(`此操作将永久删除该${msg}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res;
          if (data.data_type == "project") {
            res = await $projectDelete(data.real_id);
          } else {
            res = await $osCompanyDelete(data.real_id);
          }

          if (res.code != 200) return;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getCompanyList();
          this.getOsData();
          this.$forceUpdate();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /** 单位提交 */
    handleSubmitCompany() {
      this.$refs.companyFormData.validate(async (valid) => {
        if (valid) {
          let params = {
            ...this.companyFormData,
            sort: 100,
          };
          delete params.telItem;
          let res;
          if (params.id) {
            res = await $osCompanyUpdate(params);
          } else {
            res = await $osCompanyAdd(params);
          }
          if (res.code != 200) return;
          this.$message.success(
            (params.id && "编辑保存成功！") || "单位添加成功"
          );
          this.cancleModify("company");
          this.getCompanyList();
          this.getOsData();
          this.$forceUpdate();
        }
      });
    },
    /** 项目提交 */
    handleSubmitProject() {
      this.$refs.projectFormData.validate(async (valid) => {
        if (valid) {
          let params = {
            ...this.projectFormData,
            sort: 100,
          };
          delete params.work_order;
          let res;
          if (params.id) {
            res = await $projectUpdate(params);
          } else {
            res = await $createProject(params);
          }
          if (res.code != 200) return;
          this.$message.success(
            (params.id && "编辑保存成功！") || "项目添加成功"
          );
          this.cancleModify("project");
          this.getOsData();
          this.$forceUpdate();
        }
      });
    },
    /** 添加联系电话 */
    addCompanyTel() {
      let hasName = false;
      let hasTel = false;
      this.$refs.companyFormData.validateField("telItem.name", (valid) => {
        if (!valid) hasName = true;
      });
      this.$refs.companyFormData.validateField("telItem.tel", (valid) => {
        if (!valid) hasTel = true;
      });
      if (hasName && hasTel) {
        this.$refs.companyFormData.clearValidate([
          "telItem.name",
          "telItem.tel",
        ]);
        let telArr = this.companyFormData.tel;
        telArr.push(this.companyFormData.telItem);
        this.companyFormData = {
          ...this.companyFormData,
          tel: [...telArr],
        };
        this.companyFormData.telItem = {};
      }
    },
    /** 删除联系电话 */
    deleteCompanyTel(index) {
      let telArr = this.companyFormData.tel;
      telArr.splice(index, 1);
    },
    /** 取消单位/项目编辑 */
    cancleModify(type) {
      if (type == "company") {
        this.dialogCompanyVisible = false;
        this.companyFormData = {
          tel: [],
          telItem: {},
          status: 1,
        };
      } else if (type == "project") {
        this.dialogProjectVisible = false;
        this.projectFormData = {
          work_order: [],
          status: 1,
        };
      } else {
        this.projectId = null;
        this.codePath = "";
        this.downLoadLoading = false;
        this.dialogCodeVisible = false;
      }
    },
    /** 单位/项目添加 */
    handleAdd(type) {
      this.currentData = {};
      if (type == "company") {
        this.dialogCompanyVisible = true;
        this.$nextTick(() => {
          this.$refs.companyFormData.clearValidate();
        });
      } else {
        this.dialogProjectVisible = true;
        this.$nextTick(() => {
          this.$refs.projectFormData.clearValidate();
        });
      }
    },
    /** 工单类型选中，重新校验表单 */
    workOrderChange() {
      this.projectFormData = {
        ...this.projectFormData,
        work_order_ids: this.projectFormData.work_order.join(","),
      };
      this.$nextTick(() => {
        this.$refs.projectFormData.validateField("work_order_ids");
      });
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("containerBox");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 树形表自定义渲染函数 */
    renderContent(e, data) {
      if (data.data_type == "work_order") {
        return (
          <div class="tree-item">
            <p class="label-name">{data.name}</p>
          </div>
        );
      } else if (data.data_type == "company" && data.pid == 0) {
        if (this.smallRoleObj.update_project)
          return (
            <div class="tree-item">
              <p class="label-name">{data.name}</p>
              <el-button
                class="tree-edit"
                type="text"
                on-click={() => this.nodeModify(data)}
                icon="el-icon-edit"
              />
            </div>
          );
        else
          return (
            <div class="tree-item">
              <p class="label-name">{data.name}</p>
            </div>
          );
      } else {
        if (
          this.smallRoleObj.update_project &&
          this.smallRoleObj.delete_company
        )
          return (
            <div class="tree-item">
              <p class="label-name">{data.name}</p>
              <el-button
                class="tree-edit"
                type="text"
                on-click={() => this.nodeModify(data)}
                icon="el-icon-edit"
              />
              <el-button
                class="tree-delete"
                type="text"
                on-click={() => this.nodeDelete(data)}
                icon="el-icon-delete"
              />
            </div>
          );
        else if (this.smallRoleObj.update_project)
          return (
            <div class="tree-item">
              <p class="label-name">{data.name}</p>
              <el-button
                class="tree-edit"
                type="text"
                on-click={() => this.nodeModify(data)}
                icon="el-icon-edit"
              />
            </div>
          );
        else if (this.smallRoleObj.delete_company)
          return (
            <div class="tree-item">
              <p class="label-name">{data.name}</p>
              <el-button
                class="tree-delete"
                type="text"
                on-click={() => this.nodeDelete(data)}
                icon="el-icon-delete"
              />
            </div>
          );
        else
          return (
            <div class="tree-item">
              <p class="label-name">{data.name}</p>
            </div>
          );
      }
    },
  },
};
</script>
